<template lang="pug">
div
</template>
<script>
export default {
    mounted () {
        console.log('logging out');
        this.$auth.logout('local');
    }
};
</script>